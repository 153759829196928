<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">气象灾害</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="预警时间" prop="FBSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.FBSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择预警时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="级别" prop="YJDJ">
                    <el-input
                        v-model="formdata.dataDic.YJDJ"
                        placeholder="请输入级别"
                        :maxlength="20"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="类型" prop="YJLX">
                    <el-input
                        v-model="formdata.dataDic.YJLX"
                        placeholder="请输入类型"
                        :maxlength="20"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="说明" prop="YJXHMS">
                    <el-input
                        v-model="formdata.dataDic.YJXHMS"
                        placeholder="请输入说明"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
                <el-form-item label="是否解除" prop="SFJCYJ">
                    <el-input
                        v-model="formdata.dataDic.SFJCYJ"
                        placeholder="请输入是否解除"
                        :maxlength="20"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    >
                        <template slot="append"></template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_qxzh",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020512",
                dataDic: {
                    FBSJ: "",
                    YJDJ: "",
                    YJLX: "",
                    YJXHMS: "",
                    SFJCYJ: "",
                },
            },
            SGYJLXList: [],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
